import {Role} from '../settings/settings.objects';

export class User {
  public id: number;
  public role: Role;
  public role_id: number;
  public user_roles: UserRole[];
  public full_name: string;
  public email: string;
  public phone: string;
  public password: string;
  public is_active: boolean;
  public created_at: string;
  public updated_at: string;
  public created_by: User;
  public updated_by: User;
}

export class UserRole {
  public user_id: number;
  public role_id: number;
  public role: Role;
}

export class UserResponse {
  public status: boolean;
  public message: string;
  public result: User;
  public error: string;
}

export class UserCredential {
  public current_password: string;
  public new_password: string;
}

