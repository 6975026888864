import {Injectable} from '@angular/core';
import {RequestsService} from './requests.service';
import {User} from '../admin-pages/users/users.objects';
import {Observable} from 'rxjs';
import {LoginUser, RegisterUser, ResetPasswordUser} from '../auths-pages/auth-pages.objects';
import {DefaultService} from './default.service';
import {ActivatedRoute, Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends DefaultService {
  private token: string = '';
  private authUser: User = new User();
  private readonly TOKEN_TIMESTAMP_KEY = 'authTokenTimestamp';
  private readonly TOKEN_EXPIRATION_HOURS = 12;

  constructor(private requestService: RequestsService,private router: Router,) {
    super();
  }

  public authenticate(user: LoginUser): Observable<any> {
    const adminUser = {email: user.email, password: user.password};
    return this.requestService.sendPostRequest('auth/authenticate', adminUser, this.token);
  }

  public register(user: RegisterUser): Observable<any> {
    const adminUser = {full_name: user.full_name, email: user.email, phone: user.phone, password: user.password};
    return this.requestService.sendPostRequest('auth/register', adminUser, this.token);
  }

  public forgotPassword(user: LoginUser): Observable<any> {
    const adminUser = {email: user.email};
    return this.requestService.sendPostRequest('auth/request_password', adminUser, this.token);
  }

  public resetPassword(user: ResetPasswordUser): Observable<any> {
    return this.requestService.sendPostRequest('auth/reset_password', user, this.token);
  }

  public logout() {
    localStorage.setItem('token', '');
    localStorage.setItem('user', JSON.stringify([]));
  }

  public setToken(token: string) {
    if (token) {
      const currentTime = new Date().getTime();
      this.token = token;
      localStorage.setItem('token', this.token);
      localStorage.setItem('authTokenTimestamp', currentTime.toString());
    }
  }

  getToken(): string  {
    const token = localStorage.getItem('token');
    const tokenTimestamp = localStorage.getItem(this.TOKEN_TIMESTAMP_KEY);

    if (token && tokenTimestamp) {
      const currentTime = new Date().getTime();
      const timestamp = parseInt(tokenTimestamp, 10);
      const hoursPassed = (currentTime - timestamp) / (1000 * 60 * 60);

      if (hoursPassed < this.TOKEN_EXPIRATION_HOURS) {
        return token;
      } else {
        this.clearToken(); // Token has expired
        this.router.navigate(['/auth/login']);
        return '';
      }
    }
    return '';
  }
  clearToken(): void {
    localStorage.removeItem('token');
    localStorage.removeItem(this.TOKEN_TIMESTAMP_KEY);
    localStorage.removeItem('user');
  }
  public setUser(user: any) {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    }
  }

  public getUser(): User {
    const adminUser = localStorage.getItem('user');
    if (adminUser) {
      this.authUser = JSON.parse(adminUser);
      return this.authUser;
    }
    return new User();
  }
}
