import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NbMenuModule, NbSidebarModule, NbThemeModule} from '@nebular/theme';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NbAuthModule, NbPasswordAuthStrategy} from '@nebular/auth';
import {HttpClientModule} from '@angular/common/http';
import {NbEvaIconsModule} from '@nebular/eva-icons';
import {environment} from '../environments/environment.prod';
import {AuthGuardService} from './services/auth-guard.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NbThemeModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    NbEvaIconsModule,
    NbAuthModule.forRoot({
      strategies: [
        NbPasswordAuthStrategy.setup({
          name: 'email',
          baseEndpoint: environment.root_url,
          login: {
            endpoint: '/api/auth/authenticate',
            redirect: {
              success: '/admin/news-feeds', // welcome page path
              failure: null,
            },
          },
        //   register: {
        //     endpoint: '/api/auth/register',
        //     redirect: {
        //       success: '/auth/login', // welcome page path
        //       failure: null,
        //     },

        //   },
          requestPass: {
            endpoint: '/api/auth/request_password',
            method: 'post',
          },
          resetPass: {
            endpoint: '/api/auth/reset_password',
            method: 'post',
            resetPasswordTokenKey: 'reset_password_token',
            defaultErrors: ['Something went wrong, please try again.'],
            defaultMessages: ['Your password has been successfully changed.'],
          },
          errors: {
            key: 'error',
            getter: (module: string | number, res: { error: { message: any, errors: any }; }, options: { [x: string]: { defaultErrors: any; }; }) => {
              return res.error ? Object.values(res.error.errors)[0] : options[module].defaultErrors;
            },
          },
        }),
      ],
      forms: {},
    }),
  ],
  providers: [AuthGuardService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
