import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
@Injectable()
export class AuthGuardService implements CanActivate {
  private condition: Observable<boolean>;

  constructor(private authService: AuthService, private router: Router) {
    this.condition = new Observable<boolean>(ob => {
      ob.next(false);
    });
  }

  canActivate(): Observable<boolean> {
    this.condition = new Observable<boolean>(ob => {
      ob.next(this.authService.getToken() !== '');
    });
    return this.condition;
  }
}
