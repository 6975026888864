import {Injectable} from '@angular/core';
import Swal, {SweetAlertIcon, SweetAlertPosition} from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class DefaultService {
  public toastMessage(message: string, icon: SweetAlertIcon = 'success', timer: number = 3000, position: SweetAlertPosition = 'top-end') {
    const Toast = Swal.mixin({
      toast: true,
      position: position,
      showConfirmButton: false,
      timer: timer,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });

    Toast.fire({
      icon: icon,
      title: message,
    });
  }

  public messageDialog(title: string, message: string, icon: SweetAlertIcon) {
    Swal.fire(title, message, icon);
  }
}
